// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoginActivate-module__messageContainer___3NYh5{display:flex;flex-direction:column;justify-content:center;align-items:center;font-size:1rem;font-weight:300;width:90%;padding-bottom:10px}.LoginActivate-module__messageContainer_message___2ldzR{text-align:center;width:480px}\n", ""]);
// Exports
exports.locals = {
	"messageContainer": "LoginActivate-module__messageContainer___3NYh5",
	"messageContainer_message": "LoginActivate-module__messageContainer_message___2ldzR"
};
module.exports = exports;
