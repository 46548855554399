// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Login-module__container___2MTLM{height:100vh;width:100vw;display:flex;align-items:center;justify-content:space-between;background:#e4e4e4}.Login-module__section___27s-C{display:flex;align-items:center;justify-content:space-between;background:#e4e4e4;border-radius:0.375rem;margin-right:0;margin-left:0;height:100vh;width:100%}.Login-module__box___2o3FQ{background-color:#fff;border-radius:0.375rem;width:50%;height:100%;display:flex;justify-content:center;align-items:center}.Login-module__box___2o3FQ::before,.Login-module__box___2o3FQ ::after{box-sizing:border-box}.Login-module__boxBody___2gdM4{border-radius:0.375rem;height:100%;display:flex;justify-content:center;align-items:center}.Login-module__image___GJEZy{object-fit:cover;height:100%}\n", ""]);
// Exports
exports.locals = {
	"container": "Login-module__container___2MTLM",
	"section": "Login-module__section___27s-C",
	"box": "Login-module__box___2o3FQ",
	"boxBody": "Login-module__boxBody___2gdM4",
	"image": "Login-module__image___GJEZy"
};
module.exports = exports;
