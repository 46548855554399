// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UserDropDown-module__userSettings___2a6KE{color:var(--color-lighter-grey);margin:0;padding-top:6px;justify-content:center;align-items:center}.UserDropDown-module__logOut___ZYT_J{padding-left:20px}.UserDropDown-module__entryField___3cHmz{display:flex;align-items:center;justify-content:center}\n", ""]);
// Exports
exports.locals = {
	"userSettings": "UserDropDown-module__userSettings___2a6KE",
	"logOut": "UserDropDown-module__logOut___ZYT_J",
	"entryField": "UserDropDown-module__entryField___3cHmz"
};
module.exports = exports;
