// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoginPassForm-module__errorMessage___ZghF4{padding:0;margin:0;padding-top:20px;display:flex;text-align:center;font-size:0.8rem;color:red}.LoginPassForm-module__forgotPassContainer___1kiBs{display:flex;width:100%;justify-content:center;align-items:center}.LoginPassForm-module__forgotPassContainer_msg___3zOOE{color:var(--color-primary);font-size:0.85rem;font-weight:400;margin:0}.LoginPassForm-module__forgotPassContainer_msg___3zOOE:hover{cursor:pointer}\n", ""]);
// Exports
exports.locals = {
	"errorMessage": "LoginPassForm-module__errorMessage___ZghF4",
	"forgotPassContainer": "LoginPassForm-module__forgotPassContainer___1kiBs",
	"forgotPassContainer_msg": "LoginPassForm-module__forgotPassContainer_msg___3zOOE"
};
module.exports = exports;
