// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoginForm-module__container___1WLEo{width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center}.LoginForm-module__imageContainer___3LYTq{display:flex;justify-content:center;align-items:center;width:100%;padding-bottom:10px}.LoginForm-module__imageContainer_img___1TLfS{justify-content:center;align-items:center;height:80px}.LoginForm-module__title___3I92q{text-align:center;font-size:1.6rem;font-weight:600;padding:0px 0 10px 0;margin:0}.LoginForm-module__disclaimerLink___2_uwQ{text-decoration:none;color:var(--color-white);border-radius:6px;padding-left:10px;padding-right:10px;background:var(--color-primary);font-size:1.1rem}\n", ""]);
// Exports
exports.locals = {
	"container": "LoginForm-module__container___1WLEo",
	"imageContainer": "LoginForm-module__imageContainer___3LYTq",
	"imageContainer_img": "LoginForm-module__imageContainer_img___1TLfS",
	"title": "LoginForm-module__title___3I92q",
	"disclaimerLink": "LoginForm-module__disclaimerLink___2_uwQ"
};
module.exports = exports;
