// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoginMainForm-module__errorMessage___pTQC6{padding:0;margin:0;padding-top:20px;display:flex;text-align:center;font-size:0.8rem;color:red}\n", ""]);
// Exports
exports.locals = {
	"errorMessage": "LoginMainForm-module__errorMessage___pTQC6"
};
module.exports = exports;
