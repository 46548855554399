// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SalesSideBar-module__sidebarTitle___1BBC4{display:flex;align-items:center;justify-content:center}\n", ""]);
// Exports
exports.locals = {
	"sidebarTitle": "SalesSideBar-module__sidebarTitle___1BBC4"
};
module.exports = exports;
