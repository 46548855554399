// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SectionHeaders-module__section_headerDark__heading___2K4pg{width:100%;color:var(--white);font-size:1.375rem;line-height:1.5rem;font-weight:500;text-transform:uppercase;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.SectionHeaders-module__section_headerLight__heading___1mue9{width:100%;color:var(--color-black);font-size:1.375rem;line-height:1.5rem;font-weight:500;text-transform:uppercase;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;margin:0;color:var(--color-black);font-size:1.375rem}\n", ""]);
// Exports
exports.locals = {
	"section_headerDark__heading": "SectionHeaders-module__section_headerDark__heading___2K4pg",
	"section_headerLight__heading": "SectionHeaders-module__section_headerLight__heading___1mue9"
};
module.exports = exports;
