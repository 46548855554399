// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AddUser-module__header___3BJav{padding-top:25px;margin-bottom:-10px}.AddUser-module__formContainer___3DzN8{width:100%;padding-bottom:5px}.AddUser-module__section___2L_zZ{width:100%;display:grid;grid-template-columns:1fr 1fr;grid-gap:1rem}.AddUser-module__messageContainerLight___NlwGk{width:100%;display:flex;justify-content:center;align-items:center;text-align:center;font-size:1.1rem;font-weight:400;color:var(--color-light-black)}.AddUser-module__messageContainerLight_msg___1-Riz{width:80%}.AddUser-module__messageContainer___pRhpG{width:100%;display:flex;justify-content:center;align-items:center;text-align:center;font-size:1.1rem;font-weight:400;color:var(--color-white)}.AddUser-module__messageContainer_msg___3-9fp{width:80%}\n", ""]);
// Exports
exports.locals = {
	"header": "AddUser-module__header___3BJav",
	"formContainer": "AddUser-module__formContainer___3DzN8",
	"section": "AddUser-module__section___2L_zZ",
	"messageContainerLight": "AddUser-module__messageContainerLight___NlwGk",
	"messageContainerLight_msg": "AddUser-module__messageContainerLight_msg___1-Riz",
	"messageContainer": "AddUser-module__messageContainer___pRhpG",
	"messageContainer_msg": "AddUser-module__messageContainer_msg___3-9fp"
};
module.exports = exports;
