// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ResetPassword-module__container___3PNbP{width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center}.ResetPassword-module__imageContainer___3YgdG{display:flex;justify-content:center;align-items:center;width:100%;padding-bottom:10px}.ResetPassword-module__imageContainer_img___K5jWo{justify-content:center;align-items:center;height:80px}.ResetPassword-module__title___1M49M{text-align:center;font-size:1.6rem;font-weight:600;padding:0px 0 10px 0;margin:0}.ResetPassword-module__messageContainer___ZKG_4{display:flex;justify-content:center;align-items:center;font-size:1rem;font-weight:300}.ResetPassword-module__messageContainer_message___nJyb0{text-align:center;width:500px;margin:0}.ResetPassword-module__errorMessage___30Wvu{padding:0;margin:0;padding-top:20px;display:flex;text-align:center;font-size:0.8rem;color:red}@media (max-width: 1060px){.ResetPassword-module__messageContainer_message___nJyb0{width:400px}}@media (max-width: 836px){.ResetPassword-module__messageContainer_message___nJyb0{width:300px}}@media (max-width: 655px){.ResetPassword-module__messageContainer_message___nJyb0{width:220px}}\n", ""]);
// Exports
exports.locals = {
	"container": "ResetPassword-module__container___3PNbP",
	"imageContainer": "ResetPassword-module__imageContainer___3YgdG",
	"imageContainer_img": "ResetPassword-module__imageContainer_img___K5jWo",
	"title": "ResetPassword-module__title___1M49M",
	"messageContainer": "ResetPassword-module__messageContainer___ZKG_4",
	"messageContainer_message": "ResetPassword-module__messageContainer_message___nJyb0",
	"errorMessage": "ResetPassword-module__errorMessage___30Wvu"
};
module.exports = exports;
