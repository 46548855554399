// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UpdateDetails-module__header___fZ4w7{padding-top:25px;margin-bottom:-10px}.UpdateDetails-module__formContainer___3SkmJ{width:100%;padding-bottom:5px}.UpdateDetails-module__section___3_cTf{width:100%;display:grid;grid-template-columns:1fr 1fr;grid-gap:1rem}\n", ""]);
// Exports
exports.locals = {
	"header": "UpdateDetails-module__header___fZ4w7",
	"formContainer": "UpdateDetails-module__formContainer___3SkmJ",
	"section": "UpdateDetails-module__section___3_cTf"
};
module.exports = exports;
