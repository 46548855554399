// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoginHeaders-module__messageContainer___32xnq{display:flex;flex-direction:column;justify-content:center;align-items:center;font-size:1rem;font-weight:300;width:90%;padding-bottom:10px}.LoginHeaders-module__messageContainer_message___ZQcaX{text-align:center;width:480px}@media (max-width: 1000px){.LoginHeaders-module__messageContainer_message___ZQcaX{width:400px}}@media (max-width: 810px){.LoginHeaders-module__messageContainer_message___ZQcaX{width:300px}}@media (max-width: 570px){.LoginHeaders-module__messageContainer_message___ZQcaX{width:220px}}\n", ""]);
// Exports
exports.locals = {
	"messageContainer": "LoginHeaders-module__messageContainer___32xnq",
	"messageContainer_message": "LoginHeaders-module__messageContainer_message___ZQcaX"
};
module.exports = exports;
