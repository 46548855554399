// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ColorOptions-module__dropDown___3vxvM{display:flex;justify-content:center;flex-direction:column;align-items:center;padding-bottom:5px}.ColorOptions-module__lightModeBox___1ye-g{width:85px;height:28px;border:solid 1px var(--color-primary);border-radius:6px;cursor:pointer;background:var(--color-white);transition:var(--transition)}.ColorOptions-module__lightModeBox___1ye-g:hover{border-color:var(--color-primary);box-shadow:var(--focused-shadow)}.ColorOptions-module__darkModeBox___19_ez{width:85px;height:28px;border:solid 1px var(--color-primary);border-radius:6px;cursor:pointer;background:var(--color-light-black);transition:var(--transition)}.ColorOptions-module__darkModeBox___19_ez:hover{border-color:var(--color-light-black);box-shadow:var(--focused-shadow)}\n", ""]);
// Exports
exports.locals = {
	"dropDown": "ColorOptions-module__dropDown___3vxvM",
	"lightModeBox": "ColorOptions-module__lightModeBox___1ye-g",
	"darkModeBox": "ColorOptions-module__darkModeBox___19_ez"
};
module.exports = exports;
