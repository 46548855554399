// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".simpleModals-module__okText___2EoWv{display:flex;justify-content:center;align-items:center;padding-left:14px;padding-right:14px}\n", ""]);
// Exports
exports.locals = {
	"okText": "simpleModals-module__okText___2EoWv"
};
module.exports = exports;
